import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="load-by-date"
export default class extends Controller {
  static targets = ["consumedDate"]
  static values = { url: String }

  load(event) {
    event.preventDefault()

    let feedingDate = this.consumedDateTarget.value
    let feedingsUrl = this.urlValue
    fetch(feedingsUrl, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({consumed_at: feedingDate})
    }).then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
